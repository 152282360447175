import { Avatar, Col, Flex, Row, Typography } from 'antd';
import hadiLogo from '@assets/images/hadi.jpeg';
import packageImg from '@assets/images/kirim-aja.png';
import shopping from '@assets/images/belanja-aja.png';
import market from '@assets/images/market-aja.png';
import laundry from '@assets/images/excel-laundry.png';

import './MarketAja.less';
import { track } from '@utils/analytic.util.ts';
import { PageRoutes } from '@/routes/AppRoute.tsx';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

export default function Services() {
  const navigate = useNavigate();

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Flex vertical className={'services'}>
      <Row className={'mt-6'} gutter={[0, 24]}>
        <Col
          span={6}
          onClick={() => {
            track('CLICK_ON_SUPERMARKET');
            navigate(PageRoutes.getStore('hadi'));
          }}
        >
          <Flex vertical align={'center'} gap={'small'}>
            <Avatar size={56} src={hadiLogo} />
            <Text className={'text-xs font-bold'}>Supermarket</Text>
          </Flex>
        </Col>
        <Col
          span={6}
          onClick={() => {
            track('CLICK_ON_BELANJA_AJA');
            // navigate(PageRoutes.BELANJA_AJA);
            openInNewTab('https://wa.me/+6282229994482?text=Hi admin, saya mau belanja!');
          }}
        >
          <Flex vertical align={'center'} gap={'small'}>
            <Avatar size={56} src={shopping} className={'p-2'} />
            <Text className={'text-xs font-bold'}>Belanja-Aja</Text>
          </Flex>
        </Col>
        <Col
          span={6}
          onClick={() => {
            track('CLICK_ON_KIRIM_AJA');
            navigate(PageRoutes.KIRIM_AJA);
          }}
        >
          <Flex vertical align={'center'} gap={'small'}>
            <Avatar size={56} src={packageImg} className={'p-2'} />
            <Text className={'text-xs font-bold'}>Kirim-Aja</Text>
          </Flex>
        </Col>
        <Col
          span={6}
          onClick={() => {
            track('CLICK_ON_MARKET_AJA');
            navigate(PageRoutes.PRODUCT_SEARCH);
          }}
        >
          <Flex vertical align={'center'} gap={'small'}>
            <Avatar size={56} src={market} className={'p-2'} />
            <Text className={'text-xs font-bold'}>Market-Aja</Text>
          </Flex>
        </Col>
        <Col
          span={6}
          onClick={() => {
            track('CLICK_ON_LAUNDRY_AJA');
            openInNewTab('https://wa.me/+6282229994482?text=Hi admin, saya mau pesan layanan Laundry-Aja!');
          }}
        >
          <Flex vertical align={'center'} gap={'small'}>
            <Avatar size={56} src={laundry} className={'p-1'} />
            <Text className={'text-xs font-bold'}>Laundry</Text>
          </Flex>
        </Col>
      </Row>
    </Flex>
  );
}
